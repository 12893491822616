<template>
  <div>
    <base-info-card title="About Us">
      <base-img
        :src="require('@/assets/discover.jpg')"
        class="mb-4"
        color="grey lighten-1"
        height="196"
        tile
        width="100%"
      />

      <base-body>
        Join us as we unleash the power of ScrumOnDemand to help you and the wider community grow your potential and build skills for success.
      </base-body>
    </base-info-card>
  </div>
</template>

<script>
  export default {
    name: 'NewsSearch',
  }
</script>
